import { useEffect, useState } from "react";
import CountUp from "react-countup";

import shape1 from "../../../assets/images/counter-shape1.png";
import shape2 from "../../../assets/images/counter-shape2.png";

import img1 from "../../../assets/images/header/1.jpg";
import img2 from "../../../assets/images/header/2.jpg";
import img3 from "../../../assets/images/header/3.jpg";

import icon1 from "../../../assets/images/icons/home/1.svg";
import icon2 from "../../../assets/images/icons/home/2.svg";
import icon3 from "../../../assets/images/icons/home/3.svg";
import icon4 from "../../../assets/images/icons/home/4.svg";
import icon5 from "../../../assets/images/icons/home/5.svg";

const images = [img1, img2, img3];

function Counter() {
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="counter d-md-flex align-items-center position-relative"
      style={{ backgroundImage: `url(${currentImage})` }}
    >
      <div className="overlay d-none d-md-block"></div>
      <img src={shape1} className="w-25 position-absolute d-none d-md-block counter-shape1" style={{zIndex:`2`, left: `0px`}} />
      <div className="counter-content m-auto w-100 py-5 d-md-flex justify-content-evenly align-items-center text-center bg-light text-dark">
        <div className="mb-5 mb-md-0">
          <div className="h-50">
            <img src={icon1} />
          </div>
          <br />
          <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={2009} className="fw-bold fs-2 text-danger" />
          <h5 className="mt-2 fw-bold">
            Company
            <br />
            Established
          </h5>
        </div>

        <div className="mb-5 mb-md-0">
          <div className="h-50">
            <img src={icon2} />
          </div>

          <br />
          <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={130} className="fw-bold fs-2 text-danger" /><span className="text-danger fs-1"> +</span>
          <h5 className="mt-2 fw-bold">
            Projects
            <br />
            Completed
          </h5>
        </div>

        <div className="mb-5 mb-md-0">
          <div className="h-50">
            <img src={icon3} />
          </div>

          <br />
          <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={50} className="fw-bold fs-2 text-danger" /><span className="text-danger fs-1"> +</span>
          <h5 className="mt-2 fw-bold">
            Contractor
            <br />
            Appointed
          </h5>
        </div>

        <div className="mb-5 mb-md-0">
          <div className="h-50">
            <img src={icon4} />
          </div>

          <br />
          <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={20} className="fw-bold fs-2 text-danger" /><span className="text-danger fs-1"> +</span>
          <h5 className="mt-2 fw-bold">Clients</h5>
        </div>

        <div className="mb-5 mb-md-0">
          <div className="h-50">
            <img src={icon5} />
          </div>

          <br />
          <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={10} className="fw-bold fs-2 text-danger" /><span className="text-danger fs-1"> +</span>
          <h5 className="mt-2 fw-bold">Certificates</h5>
        </div>
      </div>
      <img src={shape2} className="w-25 position-absolute d-none d-md-block counter-shape2" style={{zIndex:`1`, right: `0px`}} />
    </div>
  );
}

export default Counter;
