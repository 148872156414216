import { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";

import img1 from "../../../assets/images/header/1.jpg";
import img2 from "../../../assets/images/header/2.jpg";
import img3 from "../../../assets/images/header/3.jpg";

const images = [img1, img2, img3];

export default function Header() {
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div
        className="header d-flex align-items-center justify-content-center position-relative"
        style={{ backgroundImage: `url(${currentImage})` }}
      >
        <div className="overlay"></div>
        <div className="header-caption text-center d-flex align-items-center justify-content-center py-3">
          <h2 className="text-light text-uppercase">
            <Slide direction="left" triggerOnce="true">Leaders in Quality Construction and Infrastructure</Slide>
          </h2>
        </div>
      </div>
    </>
  );
}
