import React from "react";

import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import img from "../../assets/images/services/mechanical.jpg";

export default function ServiceDetails() {
  return (
    <div className="background">
      <DefaultNavbar />
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-md-4">
            <img src={img} className="w-100" />
          </div>
          <div className="col-md-8">
            <h4 className="mb-4">MECHANICAL ENGINEERING</h4>
            <p className="lead mb-5">
              Global Falcom's mechanical Engineers have a thorough understanding
              of core concepts including mechanics, kinematics, thermodynamics,
              fluid mechanics, heat transfer, materials science, and energy
              relating to heating, ventilation, air conditioning, facade
              engineering and acoustics in all building types. Knowledge of
              these scientific principles help us to design ecofriendly,
              cost-effective, and sustainable systems in buildings. With the
              current emphasis on sustainability, our engineers are at the
              cutting edge of developing and managing new green building
              technologies that integrate into existing systems and services, or
              form part of new building projects.
            </p>

            <h4 className="mb-4">
              Some of the responsibilities of our Mechanical Engineers include:
            </h4>
            <ul className="lead mb-5">
              <li>Monitoring and commissioning plant and systems.</li>
              <li>Developing, testing and evaluating theoretical designs.</li>
              <li>
                Producing details of specifications and outline designs.
                Managing projects using engineering principles and techniques.
              </li>
              <li>
                Commissioning, organizing and evaluating the work of
                contractors.
              </li>
              <li>
                Considering the implications of issues such as cost, safety and
                time constraints.
              </li>
              <li>
                Discussing and solving complex problems with designers,
                subcontractors, suppliers and clients.
              </li>
              <li>
                Making decisions about expired systems equipment and the
                appropriate location of new equipment.
              </li>
              <li>
                Making sure building services systems will perform consistently
                in specified operating environments.
              </li>
              <li>
                Developing project specifications with colleagues, often
                including those from other engineering disciplines.
              </li>
              <li>
                Managing and forecasting spend, using whole life cycle costing
                techniques, ensuring that work is kept to budget.
              </li>
              <li>
                Ensuring that the design and maintenance of building systems
                meets legislative, health and safety requirements.
              </li>
              <li>
                Overseeing and supervising the installation of building systems
                and specifying maintenance and operating procedures.
              </li>
              <li>
                Using research, analytical, conceptual and planning skills,
                particularly mathematical modeling and computer-aided design.
              </li>
              <li>
                Using specialist computer-aided design ( CAD ) software and
                other resources to design the systems required for the project.
              </li>
              <li>
                Advising clients and architects on energy use and conservation
                in a range of buildings and sites, aiming to minimize the
                environmental impact and reduce the carbon footprint.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
