import React from "react";
import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/241b7660-c828-11ed-8bf6-65581b4c2d05";

export default function Contact() {
  return (
    <div className="background">
      <DefaultNavbar />
      <h4 className="pageTitle text-light py-3 px-5">CONTACT</h4>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.9993344094505!2d39.1246761856503!3d21.663883271169652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d6cbe33de467%3A0x8d278b4c7c742c4!2z2LTYsdmD2Kkg2YHYp9mE2YPZiNmFINin2YTYudin2YTZhdmK2Kkg2KfZhNiq2KzYp9ix2YrYqQ!5e0!3m2!1sar!2seg!4v1671970275020!5m2!1sar!2seg"
                width="600"
                height="450"
                title="location"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h6 className="text-danger fw-bold my-0">Inquiries</h6>
            <p>
              for any inquiries, questions or commendations,
              <br />
              please call: +966 122644991 or fill out the following form
            </p>

            <form
              action={FORM_ENDPOINT}
              method="POST"
              target="_blank"
              className="py-3 container bg-light rounded"
            >
              <div className="my-1 row">
                <div className="col-6">
                  <label htmlFor="firstName">
                    FIRST NAME: <span className="text-danger fs-5">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="firstName"
                    className="col-12 px-3 py-2"
                    required
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="lastName">
                    LAST NAME: <span className="text-danger fs-5">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="lastName"
                    className="col-12 px-3 py-2"
                    required
                  />
                </div>
              </div>

              <div className="my-1 row">
                <div className="col-6">
                  <label htmlFor="Email">
                    EMAIL ADDRESS: <span className="text-danger fs-5">*</span>
                  </label>
                  <br />
                  <input
                    type="email"
                    name="Email"
                    className="col-12 px-3 py-2"
                    required
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="phone">
                    PHONE NUMBER: <span className="text-danger fs-5">*</span>
                  </label>
                  <br />
                  <input
                    type="tel"
                    pattern="[0-9]{5,14}"
                    // pattern="[+]{1}[0-9]{11,14}"
                    name="phone"
                    className="col-12 px-3 py-2"
                    required
                  />
                </div>
              </div>

              <div className="my-1 row">
                <div className="col-12">
                  <label htmlFor="subject">SUBJECT:</label>
                  <br />
                  <input
                    type="text"
                    name="subject"
                    className="col-12 px-3 py-2"
                  />
                </div>
              </div>

              <div className="my-1 row">
                <div className="col-12">
                  <label htmlFor="subject">DESCRIPTION:</label>
                  <br />
                  <textarea
                    placeholder="Your message"
                    name="message"
                    className="px-3 py-3 col-12"
                  />
                </div>
              </div>

              <div className="my-3">
                <button className="btn btn-outline-danger px-4" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>

          <div className="col-md-6 px-5">
            <h5 className="text-danger my-0 fw-bold">Head Office</h5>
            <p>
              al-haramain plain, al-matyour center, A. al-khozaie st.
              <br />
              jeddah 23545, Saudi Arabia
            </p>
            <p>
              Email: info@gfalcom.com.sa
              <br />
              phone: +966 122644991
              <br />
              p.o Box. 33465
            </p>

            <h5 className="text-danger my-0 fw-bold">Careers</h5>
            <p>
              to apply for a job to global falcon contracting co, please send a
              cover letter with your CV to info@gfalcon.com.sa
              <br />
              <br />
              Get A Quote: 122644991
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
