import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

import logo from "../../../assets/images/Logo.png";

export default function NavHome() {
  return (
    <div className="container">
      <Navbar expand="lg" className="nav1 py-4" variant="dark">
        <Container>
          <Navbar.Brand>
            <img
              src={logo}
              className="d-inline-block align-top"
              style={{ height: "70px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <NavLink
                to="/home"
                className="nav-link py-1 nav-item position-relative"
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className="nav-link py-1 nav-item position-relative"
              >
                About
              </NavLink>
              <NavLink
                to="/services"
                className="nav-link py-1 nav-item position-relative"
              >
                Services
              </NavLink>
              <NavLink
                to="/projects"
                className="nav-link py-1 nav-item position-relative"
              >
                Projects
              </NavLink>
              <NavLink
                to="/clients"
                className="nav-link py-1 nav-item position-relative"
              >
                Clients
              </NavLink>
              <NavLink
                to="/contact"
                className="nav-link py-1 nav-item position-relative"
              >
                Contact
              </NavLink>
              <a
                className="nav-link py-1 nav-item position-relative text-light text-center p-2 rounded"
                target="_blank"
                href="https://drive.google.com/file/d/17yrD32xy7LMiWJeJ_CreNhO9RpQDXcZS/view"
                download={true}
              >
                Profile
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
