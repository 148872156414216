import React from "react";

import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import img from "../../assets/images/services/electrical.jpg";

export default function ServiceDetails() {
  return (
    <div className="background">
      <DefaultNavbar />
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-md-4">
            <img src={img} className="w-100" />
          </div>
          <div className="col-md-8">
            <h4>ELECTRICAL ENGINEERING</h4>
            <p className="lead mb-5">
              We provide electrical services that range from the design ,
              integration , installation , testing and commissioning of
              electrical new installations and systems. Previous projects have
              ranged from the relatively minor installations to projects on much
              larger scale such as the provision of an entire electrical
              installation comprising of Mains / Generator supplies ,
              Distribution Circuits including Transformers , Hoists , Tower
              Cranes , Site Set - up and lighting . Global Falcom carry out all
              electrical installation to International recognized code of
              practice for residential , commercial , office interior and
              landscape include power generators , UPS , low current , Building
              Management System ( BMS ) and IT plus security installations .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
