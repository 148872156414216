import { Route, Routes } from "react-router-dom";

import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Services from './Pages/Services/Services';
import Projects from './Pages/Projects/Projects';
import Clients from './Pages/Clients/Clients';
import Contact from './Pages/Contact/Contact';


import ElectricalEng_Service from "./Pages/Services/ElectricalEng_Service";
import Civil_Structural_Service from "./Pages/Services/Civil_Structural_Service";
import MechanicalEng_Service from "./Pages/Services/MechanicalEng_Service";
import Landscape_Infra_Service from "./Pages/Services/Landscape_Infra_Service";
import ValueEng_Service from "./Pages/Services/ValueEng_Service";


function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />

        <Route path='/services/electrical-engineering' element={<ElectricalEng_Service />} />
        <Route path='/services/civil&structural' element={<Civil_Structural_Service />} />
        <Route path='/services/mechanical-engineering' element={<MechanicalEng_Service />} />
        <Route path='/services/landscape&infrastructrue' element={<Landscape_Infra_Service />} />
        <Route path='/services/value-engineering' element={<ValueEng_Service />} />

        <Route path='/projects' element={<Projects />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
