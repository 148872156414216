import React from "react";
import { Link } from "react-router-dom";

import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import img from "../../assets/images/serviceShape.jpg";

export default function Services() {
  return (
    <div className="background">
      <DefaultNavbar />
      <h4 className="pageTitle text-light py-3 px-5">SERVICES</h4>
      <div className="container-fluid py-5 mb-5">
        <div className="row d-flex align-items-center">
          <div className="col-md-10 ps-0">
            <Link
              to={`/services/electrical-engineering`}
              style={{ textDecoration: "none" }}
            >
              <h5 className="serviceName text-light py-3 px-5 my-3 text-dark text-center">
                ELECTRICAL ENGINEERING
              </h5>
            </Link>

            <Link
              to={`/services/civil&structural`}
              style={{ textDecoration: "none" }}
            >
              <h5 className="serviceName text-light py-3 px-5 my-3 text-dark text-center">
                CIVIL AND STRUCTURAL ENGINEERING
              </h5>
            </Link>

            <Link
              to={`/services/mechanical-engineering`}
              style={{ textDecoration: "none" }}
            >
              <h5 className="serviceName text-light py-3 px-5 my-3 text-dark text-center">
                MECHANICAL ENGINEERING
              </h5>
            </Link>

            <Link
              to={`/services/landscape&infrastructrue`}
              style={{ textDecoration: "none" }}
            >
              <h5 className="serviceName text-light py-3 px-5 my-3 text-dark text-center">
                LANDSCAPE & INFRASTRUCTRUE
              </h5>
            </Link>

            <Link
              to={`/services/value-engineering`}
              style={{ textDecoration: "none" }}
            >
              <h5 className="serviceName text-light py-3 px-5 my-3 text-dark text-center">
                VALUE ENGINEERING
              </h5>
            </Link>
          </div>
          <div className="col-md-2 pe-0 d-none d-md-block">
            <img src={img} className="h-100 w-100" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
