import React from "react";

import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import img from "../../assets/images/services/value.jpg";

export default function ServiceDetails() {
  return (
    <div className="background">
      <DefaultNavbar />
      <div className="container my-5 pb-5">
        <div className="row mb-3">
          <div className="col-md-4">
            <img src={img} className="w-100" />
          </div>
          <div className="col-md-8">
            <h4>VALUE ENGINEERING</h4>
            <p className="lead mb-5">
              For Global Falcom Contracting value engineering is much more than
              simply an exercise to reduce project cost. Our goal as we enter
              into the value engineering process is to provide the project team
              with the information necessary to make informed decisions that
              will maximize the client's program requirements and budget
              constraints. Make sure your new build or refurbishment project
              goes to plan and stays on budget - by using our expert
              construction project management service. Global Falcom estimators,
              project managers and field supervisors work together to review
              each building system and component. Value, quality, and life
              expectancy are key components we use to determine which
              recommendations will best meet the needs of our clients.
            </p>
          </div>
        </div>

        <p className="lead">
          When presenting value engineering options to a client, our team
          categorizes each option according to our key considerations during
          value engineering:
          <br />
          <br />
          <span className="fw-bold">Cost Reduction </span>
          A less expensive alternative to a specified product or system, which
          may or may not include a difference in quality.
          <br />
          <br />
          <span className="fw-bold">Value Added </span>
          A higher quality product that may or may not carry a higher price but
          will bring added value to the project.
          <br />
          <br />
          <span className="fw-bold">Life Cycle Analysis </span>
          During our review of products and systems , we specifically identify
          options that will allow us to strike the proper balance between
          initial construction cost and long - term operational costs .
          <br />
          <br />
          <span className="fw-bold">Maintainability </span>
          Global Falcom Contracting review the building from the perspective of
          the owner's maintenance staff and recommend products that can produce
          long-term savings through reduced maintenance costs.
        </p>

        <h6 className="mb-3 mt-4 text-danger fw-bold">
          Value engineering practice include :
        </h6>
        <ul className="lead mb-5">
          <li>Estimating</li>
          <li>Evaluating alternative designs</li>
          <li className="mb-4">Advising on Cash Flow Forecasting</li>

          <li>Cost Analysis</li>
          <li>Undertaking feasibility studies</li>
          <li className="mb-4">
            Advising on cost limits and preparing budgets
          </li>

          <li>Investment Appraisal</li>
          <li>Forecasting expenditure flows</li>
          <li className="mb-4">
            Preparing and administering maintenance programmes
          </li>

          <li>Cost benefits Analysis</li>
          <li>Advising on Life Cycle Costing</li>
          <li>
            Measuring and describing construction work but only in terms of cost
            planning
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}
