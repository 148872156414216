import React from "react";
import { useEffect, useState } from "react";
import Footer from "../../../general components/Footer";

import img1 from "../../../assets/images/header/1.jpg";
import img2 from "../../../assets/images/header/2.jpg";
import img3 from "../../../assets/images/header/3.jpg";

const images = [img1, img2, img3];

export default function FooterHome() {
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div
        className="footerSection container-fluid pt-4 text-light position-relative"
        style={{ backgroundImage: `url(${currentImage})` }}
      >
        <div className="overlay"></div>
        <div className="row mx-md-5 footer-content">
          <div className="col-md-6 px-5">
            <h5 className="text-danger fw-bold my-0">Head Office</h5>
            <p>
              al-haramain plain, al-matyour center, A. al-khozaie st.
              <br />
              jeddah 23545, Saudi Arabia
            </p>
            <p className="mt-4">
              Email: info@gfalcom.com.sa
              <br />
              phone: +966 122644991 <br />
              p.o Box. 33465
            </p>
          </div>

          <div className="col-md-6 px-5">
            <h5 className="text-danger fw-bold my-0">Careers</h5>
            <p>
              to apply for a job to global falcom contracting co, please send a
              cover letter together with your C.V. to info@gfalcom.com.sa
              <br />
              <br />
              Get A Quote : 0122644991
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
