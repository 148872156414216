import React from "react";
import * as Icon from "react-bootstrap-icons";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container-fluid">
      <div className="row footer text-dark">
        <div className="col-md-8 px-5 text-center">
          <p className="my-3">
            &copy; {currentYear} Global Falcom - All Rights Reserved - Designed
            by{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://linktr.ee/renadnasser?utm_source=linktree_profile_share&ltsid=5ca8051c-4539-445e-9a5c-f71314316667"
            >
              Renad
            </a>{" "}
            & Developed by{" "}
            <a
              target="_blank"
              href="https://aglan.netlify.com"
              rel="noreferrer"
            >
              Mohamed Aglan
            </a>
          </p>
        </div>

        <div className="col-md-4 px-5 d-flex align-items-center justify-content-center">
          <p className="lead my-2">
            Keep in touch on
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/global-falcom/"
            >
              <Icon.Linkedin className="fs-3 mx-2" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
