import React from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/images/clients-home.png";
import img2 from "../../../assets/images/kiss2.jpg";

export default function About() {
  return (
    <>
      <div className="container my-3 py-3 ">
        <p className="section-subtitle m-0">Clients</p>
        <h4 className="mb-4 pb-3 section-title position-relative">Clients</h4>
        <div className="row">
          <div className="col-md-7 clients">
            <img src={img} className="w-75" />
            <Link
              to={`/clients`}
              style={{ textDecoration: "none" }}
            >
              <h5 className="text-danger fw-bold ps-5 mt-3">more ..</h5>
            </Link>
          </div>
          <div className="col-md-5 d-none d-md-block">
            <img src={img2} className="w-100" />
          </div>
        </div>
      </div>
    </>
  );
}
