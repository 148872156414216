import React from "react";

import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import img from "../../assets/images/services/landscape.jpg";

export default function ServiceDetails() {
  return (
    <div className="background">
      <DefaultNavbar />
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-md-4">
            <img src={img} className="w-100" />
          </div>
          <div className="col-md-8">
            <h4 className="mb-4">LANDSCAPE & INFRASTRUCTRUE</h4>
            <p className="lead mb-5">
              It's very important to consider how the landscaping design could
              impact on your construction project and to take your time to find
              a proven Landscape Contractor , like Global Falcom , that has the
              technical ability and use the best products for the scheme We have
              worked on projects ranging from public parks , hospitals , schols
              , private estates , shopping centers , universities , hotels ,
              road and rails . Global Falcom continue to be at the cutting edge
              of industry thinking and work with Main Contractors , Developers ,
              and Architects in developing and installing some of the most
              prestigious landscaping projects in the KSA.
            </p>

            <h4 className="mb-4">LANDSCAPING SERVICES</h4>
            <ul className="lead mb-5">
              <li>Paving , Paths , Drainage , Lighting , M & E</li>

              <li>Fencing, Seating, Furniture metal-wood etc</li>
              <li>Waterfeatures</li>
              <li>Roads</li>
              <li>Service Diversion</li>
              <li>civil works</li>
              <li>Design (Detail / Development)</li>
            </ul>

            <h4 className="mb-4">
              The benefits of landscaping and the use of plants can be measured
              visually and by bottom line advantages:
            </h4>
            <ul className="lead mb-5">
              <li>Better sound absorption.</li>

              <li>Contributes to ' Green Building ' design considerations.</li>
              <li>
                Pleasant environment where people can work or relax and reduces
                stress.
              </li>
              <li>
                Reduces sick building syndrome as areas with plants contain
                fewer airborne molds and bacteria.
              </li>
              <li>Service Diversion</li>
              <li>civil works</li>
              <li>
                Decreases air temperature and increases humidity to human
                comfort levels , and lowers heating and cooling costs while
                creating a healthier environment.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
