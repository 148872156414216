import React from "react";
import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import { projects } from "../../../data/projects";

import arrowLeft from "../../../assets/images/icons/arrow-left.svg";
import arrowRight from "../../../assets/images/icons/arrow-right.svg";

export default function About() {
  const [randomNumber1, setRandomNumber1] = useState(null);
  const [randomNumber2, setRandomNumber2] = useState(null);
  const [randomNumber3, setRandomNumber3] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRandomNumber1(Math.random());
      setRandomNumber2(Math.random());
      setRandomNumber3(Math.random());
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="container my-3 position-relative">
        <p className="section-subtitle m-0">PROJECTS</p>
        <h4 className="mb-4 pb-3 section-title position-relative">PROJECTS</h4>

        <div className="d-flex justify-content-evenly align-items-center w-50 m-auto">
          <button className="btn prev-project swiper-btn">
            <img src={arrowLeft} />
          </button>

          <Swiper
            navigation={{
              nextEl: ".next-project",
              prevEl: ".prev-project",
            }}
            modules={[Navigation]}
            loop={true}
            className="mySwiper w-50"
            simulateTouch={false}
          >
            {projects.map((project, index) => (
              <SwiperSlide key={index} className="my-auto">
                <h4
                  className="text-center text-danger d-none d-md-block"
                >
                  {project.title}
                </h4>
              </SwiperSlide>
            ))}
          </Swiper>

          <button className="btn next-project swiper-btn">
            <img src={arrowRight} />
          </button>
        </div>

        <Swiper
          navigation={{
            nextEl: ".next-project",
            prevEl: ".prev-project",
          }}
          modules={[Navigation]}
          loop={true}
          className="mySwiper"
          simulateTouch={false}
          allowTouchMove={false}
        >
          {projects.map((project, index) => (
            <SwiperSlide key={index}>
              <h4
                className="text-center text-danger d-block d-md-none mt-3 mb-0"
              >
                {project.title}
              </h4>
              <div className="row m-auto py-3 text-center">
                <div className="col-md-4 my-2">
                  <img
                    src={
                      project.homeImages[
                        Math.floor(randomNumber1 * project.homeImages.length)
                      ]
                    }
                  />
                </div>
                <div className="col-md-4 my-2">
                  <img
                    src={
                      project.homeImages[
                        Math.floor(randomNumber2 * project.homeImages.length)
                      ]
                    }
                  />
                </div>
                <div className="col-md-4 my-2">
                  <img
                    src={
                      project.homeImages[
                        Math.floor(randomNumber3 * project.homeImages.length)
                      ]
                    }
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
