export const services = [
    {
        id: 1,
        title: "ELECTRICAL ENGINEERING",
        desc: `We provide electrical services that range from the design ,
        integration , installation , testing and commissioning of
        electrical new installations and systems.
        
        Previous projects have ranged from the relatively minor
        installations to projects on much larger scale such as the
        provision of an entire electrical installation comprising of
        Mains / Generator supplies , Distribution Circuits including
        Transformers , Hoists , Tower Cranes , Site Set - up and
        lighting . Global Falcom carry out all electrical installation
        to International recognized code of practice for residential ,
        commercial , office interior and landscape include power
        generators , UPS , low current , Building Management System (
        BMS ) and IT plus security installations .`,
        imgSrc: require("../assets/images/services/electrical.jpg"),
        link: "electrical-engineering"
    },
    {
        id: 2,
        title: "CIVIL AND STRUCTURAL ENGINEERING",
        desc: `The developments and growth in the construction industry in KSA
        has been phenomenal in the recent decades with special impetus
        to overall improvement of living conditions . Civil Engineering
        Services is the most basic function that Global Falcom perform
        as it typically deals with construction for the common man
        specifically the building that is closest to his heart , called
        home . In this area, science & emotions run in parallel
        throughout the project duration and we have honed our skills to
        balance and satisfy both the demands. Global Falcom's engineers
        work at various stages in construction projects making us a much
        - relied team of contractors in the civil engineering field.`,
        imgSrc: require("../assets/images/services/civil.jpg"),
        link: "civil&structural"
    },
    {
        id: 3,
        title: "MECHANICAL ENGINEERING",
        desc: `Global Falcom's mechanical Engineers have a thorough understanding of core concepts including 
        mechanics, kinematics, thermodynamics, fluid mechanics, heat transfer, materials science, 
        and energy relating to heating, ventilation, air conditioning, facade engineering and acoustics in all building types.
        
        Knowledge of these scientific principles help us to design ecofriendly, cost-effective, 
        and sustainable systems in buildings.  
        With the current emphasis on sustainability, our engineers are at the cutting edge of developing and managing new green building technologies that integrate into existing systems and services, or form part of new building projects.`,
        imgSrc: require("../assets/images/services/mechanical.jpg"),
        link: "mechanical-engineering"
    },
    {
        id: 4,
        title: "LANDSCAPE & INFRASTRUCTRUE",
        desc: `
        It's very important to consider how the landscaping design could
        impact on your construction project and to take your time to find a
        proven Landscape Contractor , like Global Falcom , that has the technical ability and use the best products for the scheme
        
        We have worked on projects ranging from public parks , hospitals , schols , private estates , shopping centers , universities , hotels , road and rails . 
        
        Global Falcom continue to be at the cutting edge of industry thinking and work with Main Contractors , Developers , and Architects in developing and installing some of the most prestigious landscaping projects in the KSA .`,
        imgSrc: require("../assets/images/services/landscape.jpg"),
        link: "landscape&infrastructrue"
    },
    {
        id: 5,
        title: "VALUE ENGINEERING",
        desc: `
        For Global Falcom Contracting value engineering is much more than simply an exercise to reduce project cost.  Our goal as we enter into the value engineering process is to provide the project team with the information necessary to make informed decisions that will maximize the client's program requirements and budget constraints.
        Make sure your new build or refurbishment project goes to plan and stays on budget - by using our expert construction project management service.  
        Global Falcom estimators, project managers and field supervisors work together to review each building system and component.  
        Value, quality, and life expectancy are key components we use to determine which recommendations will best meet the needs of our clients.`,
        imgSrc: require("../assets/images/services/value.jpg"),
        link: "value-engineering"
    }
]