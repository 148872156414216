import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

import shape from "../assets/images/5.png";
import logo from "../assets/images/logo w.png";

export default function DefaultNavbar() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-9 col-7 d-flex align-items-center px-0">
          <Navbar expand="lg" className="nav-default px-5 py-3" variant="dark">
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto text-uppercase">
                  <NavLink
                    to="/home"
                    className="nav-link pe-5 py-0 position-relative"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="nav-link pe-5 py-0 position-relative"
                  >
                    About
                  </NavLink>
                  <NavLink
                    to="/services"
                    className="nav-link pe-5 py-0 position-relative"
                  >
                    Services
                  </NavLink>
                  <NavLink
                    to="/projects"
                    className="nav-link pe-5 py-0 position-relative"
                  >
                    Projects
                  </NavLink>
                  <NavLink
                    to="/clients"
                    className="nav-link pe-5 py-0 position-relative"
                  >
                    Clients
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="nav-link pe-5 py-0 position-relative"
                  >
                    Contact
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="col-md-3 col-5 pe-0 position-relative">
          <img src={shape} className="w-100" />
          <img src={logo} className="w-25 position-absolute nav-logo" style={{top: `10px`}}/>
        </div>
      </div>
    </div>
  );
}