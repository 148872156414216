import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";

import { services } from "../../../data/services";

import arrowLeft from "../../../assets/images/icons/arrow-left.svg";
import arrowRight from "../../../assets/images/icons/arrow-right.svg";

import img from "../../../assets/images/serviceShape.jpg";

export default function Services() {
  return (
    <div className="container-fluid ps-5 my-5">
      <p className="section-subtitle m-0 ms-lg-5">SERVICES</p>
      <h4 className="mb-4 pb-3 section-title position-relative ms-lg-5">SERVICES</h4>

      <div className="row d-flex align-items-center">
        <div className="col-12 col-lg-10 ps-lg-5">
          <Swiper
            navigation={{
              nextEl: ".next-service",
              prevEl: ".prev-service",
            }}
            modules={[Navigation]}
            loop={true}
            className="mySwiper"
          >
            {services.map((service, index) => (
              <SwiperSlide key={index}>
                <div className="row">
                  <div className="col-md-4">
                    <img src={service.imgSrc} className="w-100 " />
                  </div>
                  <div className="col-md-6">
                    <h4 className="mb-4">{service.title}</h4>
                    <p className="lead">{service.desc}</p>
                    <Link
                      to={`/services/${service.link}`}
                      state={service}
                      style={{ textDecoration: "none" }}
                    >
                      <h5 className="text-danger fw-bold">more ..</h5>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="col-lg-2 pe-0 d-none d-lg-block">
          <img src={img} className="h-100 w-100" style={{mixBlendMode: `multiply`}} />
        </div>
      </div>

      <div className="col-3 d-flex justify-content-center mb-2">
        <button className="btn prev-service swiper-btn pe-0">
          <img src={arrowLeft} />
        </button>
        <button className="btn next-service swiper-btn ps-0">
          <img src={arrowRight} />
        </button>
      </div>
    </div>
  );
}
