import { React, useState } from "react";
import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import { projects } from "../../data/projects";
import arrowLeft from "../../assets/images/icons/arrow-left.svg";
import arrowRight from "../../assets/images/icons/arrow-right.svg";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";

export default function Projects() {
  const [isActive, setIsActive] = useState(false);
  const [selectedImgs, setSelectedImgs] = useState([]);

  const handleClick = (index) => {
    setSelectedImgs([index]);
    setIsActive((current) => !current);
  };

  return (
    <div className="background">
      <DefaultNavbar />
      <h4 className="pageTitle text-light py-3 px-5">PROJECTS</h4>
      <div className="projects container-fluid ps-0 py-4 my-4">
        {projects.map((project, index1) => (
          <div className="row my-4 py-2" key={index1}>
            <div className="col-md-6 d-flex align-items-center">
              <h5 className="pageTitle text-light py-3 px-5 my-3 text-dark w-100 text-center">
                {project.title}
              </h5>
            </div>
            <div className="col-md-6 row">
              <div className="d-flex">
                <button className={`prev-img-${project.id} col-2 swiper-btn`}>
                  <img src={arrowLeft} />
                </button>

                <Swiper
                  navigation={{
                    nextEl: `.next-img-${project.id}`,
                    prevEl: `.prev-img-${project.id}`,
                  }}
                  modules={[Navigation]}
                  loop={true}
                  className="mySwiper"
                >
                  {project.pageImages.map((img, index) => (
                    <SwiperSlide key={index}>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={img}
                          className={
                            (selectedImgs.includes(index1) && isActive) ? "width100 h-100" : "h-100 project-img"
                          }
                          onClick={() => handleClick(index1)}
                          style={{cursor: `pointer`}}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <button className={`next-img-${project.id} col-2 swiper-btn`}>
                  <img src={arrowRight} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
