import React from "react";
import img from "../../../assets/images/about.jpg";
import { Slide } from "react-awesome-reveal";

export default function About() {
  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col-md-6">
            <Slide triggerOnce="true">
              <img src={img} alt="engineers pic" className="h-100 w-100" />
            </Slide>
          </div>
          <div className="col-md-6 py-4 my-md-5">
            <Slide direction="right" triggerOnce="true">
              <h3 className="section-subtitle">FALCOM</h3>
              <h4 className="mb-4 pb-3 section-title">ABOUT US</h4>
              <p className="mt-5 fs-5">
                Global Falcom Contracting is a Saudi based company with
                expertise in construction, development of commercial and
                residential projects and public private partnerships. We carry
                out all aspects of the construction, development and
                infrastructure process - from financing projects, through design
                and construction right through to facilities management,
                Operation, and maintenance
              </p>
            </Slide>
          </div>
        </div>
      </div>
    </>
  );
}
