export const projects = [
    {
        id: 1,
        title: `KNOWLEDGE ECONOMIC CITY MADINA`,
        homeImages: [
            require("../assets/images/projects/knowledge economic city/homeSection/1.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/2.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/3.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/4.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/5.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/6.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/7.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/8.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/9.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/10.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/11.jpg"),
            require("../assets/images/projects/knowledge economic city/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/knowledge economic city/projectsPage/1.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/2.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/3.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/4.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/5.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/6.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/7.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/8.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/9.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/10.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/11.jpg"),
            require("../assets/images/projects/knowledge economic city/projectsPage/12.jpg"),
        ]
    },
    {
        id: 2,
        title: `BOULEVARD RIYADH CITY`,
        homeImages: [
            require("../assets/images/projects/boulevard riyadh city/homeSection/1.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/2.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/3.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/4.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/5.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/6.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/7.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/8.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/9.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/10.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/11.jpg"),
            require("../assets/images/projects/boulevard riyadh city/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/boulevard riyadh city/projectsPage/1.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/2.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/3.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/4.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/5.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/6.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/7.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/8.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/9.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/10.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/11.jpg"),
            require("../assets/images/projects/boulevard riyadh city/projectsPage/12.jpg"),
        ]
    },
    {
        id: 3,
        title: `COMPLETION OF PHASE 9 PROJECT IN MAKKAH SECTOR`,
        homeImages: [
            require("../assets/images/projects/makkah sector/homeSection/1.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/2.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/3.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/4.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/5.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/6.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/7.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/8.jpg"),
            require("../assets/images/projects/makkah sector/homeSection/9.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/makkah sector/projectsPage/1.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/2.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/3.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/4.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/5.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/6.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/7.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/8.jpg"),
            require("../assets/images/projects/makkah sector/projectsPage/9.jpg"),
        ]
    },
    {
        id: 4,
        title: `DARRIYAH GATE PROJECT`,
        homeImages: [
            require("../assets/images/projects/darriyah gate/homeSection/1.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/2.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/3.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/4.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/5.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/6.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/7.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/8.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/9.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/10.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/11.jpg"),
            require("../assets/images/projects/darriyah gate/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/darriyah gate/projectsPage/1.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/2.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/3.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/4.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/5.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/6.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/7.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/8.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/9.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/10.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/11.jpg"),
            require("../assets/images/projects/darriyah gate/projectsPage/12.jpg"),
        ]
    },
    {
        id: 5,
        title: `DIVERSION OF LINE 600 MM & 
        700 MM IN ALMAHJAR DISTRICT JEDDAH`,
        homeImages: [
            require("../assets/images/projects/almahjar district/homeSection/1.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/2.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/3.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/4.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/5.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/6.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/7.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/8.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/9.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/10.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/11.jpg"),
            require("../assets/images/projects/almahjar district/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/almahjar district/projectsPage/1.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/2.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/3.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/4.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/5.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/6.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/7.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/8.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/9.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/10.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/11.jpg"),
            require("../assets/images/projects/almahjar district/projectsPage/12.jpg"),
        ]
    },
    {
        id: 6,
        title: `HARMAIN HIGH SPEED RAIL - UPDATE EXISITING `,
        homeImages: [
            require("../assets/images/projects/harmain/homeSection/1.jpg"),
            require("../assets/images/projects/harmain/homeSection/2.jpg"),
            require("../assets/images/projects/harmain/homeSection/3.jpg"),
            require("../assets/images/projects/harmain/homeSection/4.jpg"),
            require("../assets/images/projects/harmain/homeSection/5.jpg"),
            require("../assets/images/projects/harmain/homeSection/6.jpg"),
            require("../assets/images/projects/harmain/homeSection/7.jpg"),
            require("../assets/images/projects/harmain/homeSection/8.jpg"),
            require("../assets/images/projects/harmain/homeSection/9.jpg"),
            require("../assets/images/projects/harmain/homeSection/10.jpg"),
            require("../assets/images/projects/harmain/homeSection/11.jpg"),
            require("../assets/images/projects/harmain/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/harmain/projectsPage/1.jpg"),
            require("../assets/images/projects/harmain/projectsPage/2.jpg"),
            require("../assets/images/projects/harmain/projectsPage/3.jpg"),
            require("../assets/images/projects/harmain/projectsPage/4.jpg"),
            require("../assets/images/projects/harmain/projectsPage/5.jpg"),
            require("../assets/images/projects/harmain/projectsPage/6.jpg"),
            require("../assets/images/projects/harmain/projectsPage/7.jpg"),
            require("../assets/images/projects/harmain/projectsPage/8.jpg"),
            require("../assets/images/projects/harmain/projectsPage/9.jpg"),
            require("../assets/images/projects/harmain/projectsPage/10.jpg"),
            require("../assets/images/projects/harmain/projectsPage/11.jpg"),
            require("../assets/images/projects/harmain/projectsPage/12.jpg"),
        ]
    },
    {
        id: 7,
        title: `JEDDAH CORNICH CIRCUIT FORMULA 1 
        PARKING FACILITY`,
        homeImages: [
            require("../assets/images/projects/jeddah cornich/homeSection/1.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/2.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/3.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/4.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/5.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/6.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/7.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/8.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/9.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/10.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/11.jpg"),
            require("../assets/images/projects/jeddah cornich/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/jeddah cornich/projectsPage/1.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/2.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/3.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/4.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/5.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/6.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/7.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/8.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/9.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/10.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/11.jpg"),
            require("../assets/images/projects/jeddah cornich/projectsPage/12.jpg"),
        ]
    },
    {
        id: 8,
        title: `JEDDAH YACHT CLUB`,
        homeImages: [
            require("../assets/images/projects/jeddah yacht club/homeSection/1.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/2.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/3.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/4.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/5.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/6.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/7.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/8.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/9.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/10.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/11.jpg"),
            require("../assets/images/projects/jeddah yacht club/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/jeddah yacht club/projectsPage/1.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/2.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/3.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/4.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/5.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/6.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/7.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/8.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/9.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/10.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/11.jpg"),
            require("../assets/images/projects/jeddah yacht club/projectsPage/12.jpg"),
        ]
    },
    {
        id: 9,
        title: `NEOM - CONSTRUCTION OF PIPE LINE`,
        homeImages: [
            require("../assets/images/projects/neom/homeSection/1.jpg"),
            require("../assets/images/projects/neom/homeSection/2.jpg"),
            require("../assets/images/projects/neom/homeSection/3.jpg"),
            require("../assets/images/projects/neom/homeSection/4.jpg"),
            require("../assets/images/projects/neom/homeSection/5.jpg"),
            require("../assets/images/projects/neom/homeSection/6.jpg"),
            require("../assets/images/projects/neom/homeSection/7.jpg"),
            require("../assets/images/projects/neom/homeSection/8.jpg"),
            require("../assets/images/projects/neom/homeSection/9.jpg"),
            require("../assets/images/projects/neom/homeSection/10.jpg"),
            require("../assets/images/projects/neom/homeSection/11.jpg"),
            require("../assets/images/projects/neom/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/neom/projectsPage/1.jpg"),
            require("../assets/images/projects/neom/projectsPage/2.jpg"),
            require("../assets/images/projects/neom/projectsPage/3.jpg"),
            require("../assets/images/projects/neom/projectsPage/4.jpg"),
            require("../assets/images/projects/neom/projectsPage/5.jpg"),
            require("../assets/images/projects/neom/projectsPage/6.jpg"),
            require("../assets/images/projects/neom/projectsPage/7.jpg"),
            require("../assets/images/projects/neom/projectsPage/8.jpg"),
            require("../assets/images/projects/neom/projectsPage/9.jpg"),
            require("../assets/images/projects/neom/projectsPage/10.jpg"),
            require("../assets/images/projects/neom/projectsPage/11.jpg"),
            require("../assets/images/projects/neom/projectsPage/12.jpg"),
        ]
    },
    {
        id: 10,
        title: `SECURITY FORCED MEDICAL COMPLEX DHAHBAN`,
        homeImages: [
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/1.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/2.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/3.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/4.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/5.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/6.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/7.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/8.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/9.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/10.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/11.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/1.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/2.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/3.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/4.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/5.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/6.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/7.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/8.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/9.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/10.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/11.jpg"),
            require("../assets/images/projects/security forced medical complex dhahban/projectsPage/12.jpg"),
        ]
    },
    // {
    //     id: 11,
    //     title: `SEWEGE AND WATER NETWORK FOR SPECIALIZED 
    //     MAINTENANCE CENTER KAA`
    // },
    {
        id: 12,
        title: `SEWERAGE NETWORK AND CONNECTIONS 
        FOR TAHLIA CENTER JEDDAH`,
        homeImages: [
            require("../assets/images/projects/sewerage network/homeSection/1.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/2.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/3.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/4.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/5.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/6.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/7.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/8.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/9.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/10.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/11.jpg"),
            require("../assets/images/projects/sewerage network/homeSection/12.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/sewerage network/projectsPage/1.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/2.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/3.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/4.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/5.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/6.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/7.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/8.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/9.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/10.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/11.jpg"),
            require("../assets/images/projects/sewerage network/projectsPage/12.jpg"),
        ]
    },
    {
        id: 13,
        title: `AL-RAIDAH HOUSING PROJECT`,
        homeImages: [
            require("../assets/images/projects/al-raidah housing/homeSection/1.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/2.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/3.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/4.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/5.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/6.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/7.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/8.jpg"),
            require("../assets/images/projects/al-raidah housing/homeSection/9.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/al-raidah housing/projectsPage/1.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/2.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/3.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/4.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/5.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/6.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/7.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/8.jpg"),
            require("../assets/images/projects/al-raidah housing/projectsPage/9.jpg"),
        ]
    },
    {
        id: 14,
        title: `JIZAN ECONOMY CITY`,
        homeImages: [
            require("../assets/images/projects/jizan economy city/homeSection/1.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/2.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/3.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/4.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/5.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/6.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/7.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/8.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/9.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/10.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/11.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/12.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/13.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/14.jpg"),
            require("../assets/images/projects/jizan economy city/homeSection/15.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/jizan economy city/projectsPage/1.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/2.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/3.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/4.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/5.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/6.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/7.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/8.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/9.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/10.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/11.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/12.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/13.jpg"),
            require("../assets/images/projects/jizan economy city/projectsPage/14.jpg"),
        ]
    },
    {
        id: 15,
        title: `RIYADH METRO LINE 3`,
        homeImages: [
            require("../assets/images/projects/riyadh metro line/homeSection/1.jpg"),
            require("../assets/images/projects/riyadh metro line/homeSection/2.jpg"),
            require("../assets/images/projects/riyadh metro line/homeSection/3.jpg"),
            require("../assets/images/projects/riyadh metro line/homeSection/4.jpg"),
            require("../assets/images/projects/riyadh metro line/homeSection/5.jpg"),
            require("../assets/images/projects/riyadh metro line/homeSection/6.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/riyadh metro line/projectsPage/1.jpg"),
            require("../assets/images/projects/riyadh metro line/projectsPage/2.jpg"),
            require("../assets/images/projects/riyadh metro line/projectsPage/3.jpg"),
            require("../assets/images/projects/riyadh metro line/projectsPage/4.jpg"),
            require("../assets/images/projects/riyadh metro line/projectsPage/5.jpg"),
            require("../assets/images/projects/riyadh metro line/projectsPage/6.jpg"),
        ]
    },
    {
        id: 16,
        title: `SAUDI ARABIA NATIONAL GAURDS`,
        homeImages: [
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/1.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/2.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/3.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/4.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/5.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/6.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/7.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/8.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/9.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/10.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/11.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/12.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/13.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/14.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/homeSection/15.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/1.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/2.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/3.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/4.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/5.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/6.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/7.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/8.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/9.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/10.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/11.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/12.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/13.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/14.jpg"),
            require("../assets/images/projects/saudi arabia national gaurds/projectsPage/15.jpg"),
        ]
    },
    {
        id: 17,
        title: `KING ABDULLAH SPORTS CITY`,
        homeImages: [
            require("../assets/images/projects/king abdullah sports city/homeSection/1.jpg"),
            require("../assets/images/projects/king abdullah sports city/homeSection/2.jpg"),
            require("../assets/images/projects/king abdullah sports city/homeSection/3.jpg"),
            require("../assets/images/projects/king abdullah sports city/homeSection/4.jpg"),
            require("../assets/images/projects/king abdullah sports city/homeSection/5.jpg"),
            require("../assets/images/projects/king abdullah sports city/homeSection/6.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/king abdullah sports city/projectsPage/1.jpg"),
            require("../assets/images/projects/king abdullah sports city/projectsPage/2.jpg"),
            require("../assets/images/projects/king abdullah sports city/projectsPage/3.jpg"),
            require("../assets/images/projects/king abdullah sports city/projectsPage/4.jpg"),
            require("../assets/images/projects/king abdullah sports city/projectsPage/5.jpg"),
            require("../assets/images/projects/king abdullah sports city/projectsPage/6.jpg"),
        ]
    },
    {
        id: 18,
        title: `SECURITY FORCES MEDICAL COMPLEX`,
        homeImages: [
            require("../assets/images/projects/security forced/homeSection/1.jpg"),
            require("../assets/images/projects/security forced/homeSection/2.jpg"),
            require("../assets/images/projects/security forced/homeSection/3.jpg"),
            require("../assets/images/projects/security forced/homeSection/4.jpg"),
            require("../assets/images/projects/security forced/homeSection/5.jpg"),
            require("../assets/images/projects/security forced/homeSection/6.jpg"),
            require("../assets/images/projects/security forced/homeSection/7.jpg"),
            require("../assets/images/projects/security forced/homeSection/8.jpg"),
            require("../assets/images/projects/security forced/homeSection/9.jpg"),
            require("../assets/images/projects/security forced/homeSection/10.jpg"),
            require("../assets/images/projects/security forced/homeSection/11.jpg"),
            require("../assets/images/projects/security forced/homeSection/12.jpg"),
            require("../assets/images/projects/security forced/homeSection/13.jpg"),
            require("../assets/images/projects/security forced/homeSection/14.jpg"),
            require("../assets/images/projects/security forced/homeSection/15.jpg"),
        ],
        pageImages: [
            require("../assets/images/projects/security forced/projectsPage/1.jpg"),
            require("../assets/images/projects/security forced/projectsPage/2.jpg"),
            require("../assets/images/projects/security forced/projectsPage/3.jpg"),
            require("../assets/images/projects/security forced/projectsPage/4.jpg"),
            require("../assets/images/projects/security forced/projectsPage/5.jpg"),
            require("../assets/images/projects/security forced/projectsPage/6.jpg"),
            require("../assets/images/projects/security forced/projectsPage/7.jpg"),
            require("../assets/images/projects/security forced/projectsPage/8.jpg"),
            require("../assets/images/projects/security forced/projectsPage/9.jpg"),
            require("../assets/images/projects/security forced/projectsPage/10.jpg"),
            require("../assets/images/projects/security forced/projectsPage/11.jpg"),
            require("../assets/images/projects/security forced/projectsPage/12.jpg"),
            require("../assets/images/projects/security forced/projectsPage/13.jpg"),
        ]
    },
];