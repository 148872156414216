import React from "react";
import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import icon1 from "../../assets/images/icons/about/gray/1.svg";
import icon2 from "../../assets/images/icons/about/gray/2.svg";
import icon3 from "../../assets/images/icons/about/gray/3.svg";
import icon4 from "../../assets/images/icons/about/gray/4.svg";
import icon5 from "../../assets/images/icons/about/gray/5.svg";

import icon1c from "../../assets/images/icons/about/colored/1.svg";
import icon2c from "../../assets/images/icons/about/colored/2.svg";
import icon3c from "../../assets/images/icons/about/colored/3.svg";
import icon4c from "../../assets/images/icons/about/colored/4.svg";
import icon5c from "../../assets/images/icons/about/colored/5.svg";

export default function About() {
  return (
    <div className="background">
      <DefaultNavbar />
      <h4 className="pageTitle text-light py-3 px-5">OUR STRATEGY & VALUES</h4>
      <div className="container">
        <div className="strategy my-5">
          <h4 className="mb-4 pb-3 section-title position-relative">
            OUR STRATEGY
          </h4>
          <p className="lead">
            Our focus is on creating sustainable solutions and we aim to be
            leaders in green construction , health and safety and business
            ethics . We also recognize that we have a responsibility to the
            communities , in which we work and this is something we take very
            seriously , working closely with those communities to keep them
            fully informed regarding the activities on our sites .
          </p>

          <h5 className="mt-5 mb-3 fw-bold">
            Global Falcom Contracting's strategy for achieving its operational
            and financial goal is:
          </h5>

          <ul className="lead">
            <li>To capitalize on urbanization trends.</li>
            <li>To foresee and systematically manage risk</li>
            <li>To enhance green expertise throughout our operations</li>
            <li>To focus on core businesses in construction and development</li>
            <li>
              To recruit, develop and retain competent employees and to achieve
              a greater diversity
            </li>
            <li>
              To take advantage of Global Falcom's collective resources - brand
              , employee expertise and financial strength
            </li>
            <li>
              To be an industry leader in green building and sustainability , in
              particularly in occupational health a Isafety , ethics and
              environment
            </li>
          </ul>
        </div>

        <div className="values">
          <h4 className="mb-4 pb-3 section-title position-relative">
            OUR VALUES
          </h4>
          <p className="lead">
            Clients , customers , investors and employees all care about what
            kind of company Global Falcom is . To take on board these demands
            and maintain the brand , we strive to succeed in our values of :
          </p>

          <div className="icons row py-2 my-4 d-flex justify-content-around">
            <div
              className="text-center mb-3 d-flex flex-column justify-content-between"
              style={{ width: `fit-content` }}
            >
              <div className="h-50">
                <img src={icon1} className="h-100" />
              </div>
              <p className="fs-6 fw-bold">Zero loss - making projects</p>
            </div>
            <div
              className="text-center mb-3 d-flex flex-column justify-content-between"
              style={{ width: `fit-content` }}
            >
              <div className="h-50">
                <img src={icon2} className="h-100" />
              </div>
              <p className="fs-6 fw-bold">Zero accidents</p>
            </div>
            <div
              className="text-center mb-3 d-flex flex-column justify-content-between"
              style={{ width: `fit-content` }}
            >
              <div className="h-50">
                <img src={icon3} className="h-100" />
              </div>
              <p className="fs-6 fw-bold">Zero environmental incidents</p>
            </div>
            <div
              className="text-center mb-3 d-flex flex-column justify-content-between"
              style={{ width: `fit-content` }}
            >
              <div className="h-50">
                <img src={icon4} className="h-100" />
              </div>
              <p className="fs-6 fw-bold">Zero ethical breaches</p>
            </div>
            <div
              className="text-center mb-3 d-flex flex-column justify-content-between"
              style={{ width: `fit-content` }}
            >
              <div className="h-50">
                <img src={icon5} className="h-100" />
              </div>
              <p className="fs-6 fw-bold">Zero defects</p>
            </div>
          </div>
        </div>
      </div>

      <h4 className="pageTitle text-light py-3 px-5 my-5">OUR TARGETS</h4>
      <div className="container">
        <div className="qualitative">
          <h4 className="mb-4 pb-3 section-title position-relative">
            QUALITATIVE MEASURES
          </h4>

          <p className="lead">
            Global Falcom aims to lead the field in both commercial success and
            quality.
            <br />
            Our financial targets reflect our ambition to exceed the industry
            norm.
            <br />
            We have established financial performance goals.
            <br />
            In addition to the financial goals - and as a means to reach them-
            we have also adopted <br />
            qualitative measures.
          </p>

          <div className="details container">
            <h3 className="text-center my-5 fw-bold">
              The qualitative measures are expressed as follows:
            </h3>

            <div className="colored-icons">
              <div className="row">
                <div className="col-md-4 text-center px-5">
                  <div className="h-50 mb-2">
                    <img src={icon1c} />
                  </div>
                  <h6 className="fw-bold mb-0">Zero loss - making projects</h6>
                  <p>
                    Loss makers not only destroy profitability, but also affect
                    customer relationships.
                  </p>
                </div>
                <div className="col-md-4 text-center px-5">
                  <div className="h-50 mb-2">
                    <img src={icon2c} />
                  </div>
                  <h6 className="fw-bold mb-0">Zero accidents</h6>
                  <p>
                    Whereby the safety of our personnel as well as
                    subcontractors, suppliers and the general public is ensured.
                  </p>
                </div>
                <div className="col-md-4 text-center px-5">
                  <div className="h-50 mb-2">
                    <img src={icon3c} />
                  </div>
                  <h6 className="fw-bold mb-0">Zero environmental incidents</h6>
                  <p>
                    which means our projects should be implemented in a manner
                    that minimizes environmental impact.
                  </p>
                </div>
              </div>

              <div className="row mt-4 justify-content-around">
                <div className="col-md-5 text-center px-5">
                  <div className="h-50 mb-3">
                    <img src={icon4c} />
                  </div>
                  <h6 className="fw-bold mb-0">Zero ethical breaches</h6>
                  <p>
                    Meaning that we take a zero tolerance approach to any form
                    of bribery or corruption.
                  </p>
                </div>
                <div className="col-md-5 text-center px-5">
                  <div className="h-50 mb-3">
                    <img src={icon5c} />
                  </div>
                  <h6 className="fw-bold mb-0">Zero defects</h6>
                  <p>
                    With the double aim of improving the bottom line and
                    increasing customer satisfaction.
                  </p>
                </div>
              </div>
            </div>

            <h6 className="fw-bold text-center my-5">
              The qualitative targets, as expressed in the five measures,
              reflect our core values. The five measures, as well as the
              financial performance goals, provide the basis for incentive
              systems at various levels within Global Falcom.
            </h6>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
