import React from "react";
import DefaultNavbar from "../../general components/DefaultNavbar";
import img from "../../assets/images/clients.jpg";
import img2 from "../../assets/images/about.jpg";
import Footer from "../../general components/Footer";

export default function ClientsPage() {
  return (
    <div className="background">
      <DefaultNavbar />
      <h4 className="pageTitle text-light py-3 px-5">CLIENTS</h4>
      <div className="container my-3 py-3 ">
        <div className="row">
          <div className="col-md-7 clients">
            <img src={img} className="w-100" />
          </div>
          <div className="col-md-5">
            <img src={img2} className="w-100 h-100" style={{transform: `scaleX(-1)`}}/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
