import React from "react";

import DefaultNavbar from "../../general components/DefaultNavbar";
import Footer from "../../general components/Footer";

import img from "../../assets/images/services/civil.jpg";

export default function ServiceDetails() {
  return (
    <div className="background">
      <DefaultNavbar />
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-md-4">
            <img src={img} className="w-100" />
          </div>
          <div className="col-md-8">
            <h4 className="mb-4">CIVIL AND STRUCTURAL ENGINEERING</h4>
            <p className="lead mb-5">
              The developments and growth in the construction industry in KSA
              has been phenomenal in the recent decades with special impetus to
              overall improvement of living conditions . Civil Engineering
              Services is the most basic function that Global Falcom perform as
              it typically deals with construction for the common man
              specifically the building that is closest to his heart , called
              home . In this area, science & emotions run in parallel throughout
              the project duration and we have honed our skills to balance and
              satisfy both the demands. Global Falcom's engineers work at
              various stages in construction projects making us a much - relied
              team of contractors in the civil engineering field.
            </p>

            <h4 className="mb-4">Structural Engineering</h4>
            <p className="lead mb-5">
              We offers structural engineering services when the project
              proportions are large, when a group of small structures for
              different purposes is to be housed under one roof, when soil
              and/or water quality, wind dynamics and such other environmental
              conditions pose challenges to be overcome at site, considering the
              size of the overall project, interdependency of functions, space &
              other constraints.<br/><br/>In practice, every structure that is
              scientifically constructed goes through such considerations;
              However the sheer number of simple structures as in homes,
              warehouses, halls and others lends for a certain degree of
              generalization in practice, which large structures do not
              permit.<br/><br/>Hence, we undertake detailed studies before commencing on
              structural design and translate our findings into detailed
              engineering drawings which takes care of every condition that
              exists on the ground, and helps assess requirements of steel &
              concrete at one glance.
            </p>

            <h4 className="mb-4">
              Civil Design Experience for Industrial and Commercial Projects
            </h4>

            <ul className="lead mb-5">
              <li>Content Calculations</li>
              <li>Drainage & Grading</li>
              <li>firewater</li>
              <li>Site Development</li>
              <li>Underground Piping Systems</li>
            </ul>

            <h4 className="mb-4">
              Structural Design Experience for Industrial and Commercial
              Projects
            </h4>
            <ul className="lead mb-5">
              <li>concrete sumps</li>
              <li>Masonry Retaining Walls</li>
              <li>Steel Monorails & Crane</li>
              <li>Girders Concrete footings, mats & pile caps</li>
              <li> Concrete Slabs on Grade & Elevated Slabs</li>
              <li>Masonry Buildings</li>
              <li>Steel Pipe - racks & Pipe Supports</li>
              <li>Steel open and closed buildings</li>
              <li>Concrete Retaining & Containment Walls</li>
              <li>Steel Platforms & Equipment Support Structures</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
