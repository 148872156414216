import React from "react";
import NavHome from "./components/NavHome";
import Header from "./components/Header";
import About from "./components/About";
import Counter from "./components/Counter";
import Services from "./components/Services";
import Projects from "./components/Projects";
import Clients from "./components/Clients";
import FooterHome from "./components/FooterHome";

export default function Home() {
  return (
    <div className="background">
      <NavHome />
      <Header />
      <About />
      <Counter />
      <Services />
      <Projects />
      <Clients />
      <FooterHome />
    </div>
  );
}
